const contentData = [
  {
    title: "Configure other identity providers",
    link: "https://auth0.com/docs/connections",
    description:
      "Auth0 supports social providers as Facebook, Twitter, Instagram and 100+, Enterprise providers as Microsoft Office 365, Google Apps, Azure, and more. You can also use any OAuth2 Authorization Server."
  },
  {
    title: "Enable Multifactor Authentication",
    link: "https://auth0.com/docs/multifactor-authentication",
    description:
      "Add an extra layer of security by enabling Multi-factor Authentication, requiring your users to provide more than one piece of identifying information. Push notifications, authenticator apps, SMS, and DUO Security are supported."
  },
  {
    title: "Anomaly Detection",
    link: "https://auth0.com/docs/anomaly-detection",
    description:
      "Auth0 can detect anomalies and stop malicious attempts to access your application. Anomaly detection can alert you and your users of suspicious activity, as well as block further login attempts."
  },
  {
    title: "Learn About Rules",
    link: "https://auth0.com/docs/rules",
    description:
      "Rules are JavaScript functions that execute when a user authenticates to your application. They run once the authentication process is complete, and you can use them to customize and extend Auth0's capabilities."
  }
];

export default contentData;


export const initSteps = [
  {
      label: 'CV',
      description: ``,
      type: 'CvPresentation',
      active: true,
      noHandle: true,
  },
  {
      label: 'Choisir un type d\'analyse',
      description:
          '',
      type: 'ChooseFlowType',
      noHandle: true,
      active: true
  }
];



export function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}


// Function to save data to localStorage
export const setLocalStorageItem = (key, value) => {
  try {
      localStorage.setItem(key, JSON.stringify(value));
      console.log(`Saved: ${key} = ${value}`);
  } catch (error) {
      console.error('Error saving to localStorage', error);
  }
};

// Function to retrieve data from localStorage
export const getLocalStorageItem = (key) => {
  try {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
  } catch (error) {
      console.error('Error reading from localStorage', error);
      return null;
  }
};


// Function to convert HSV to RGB
function hsvToRgb(h, s, v) {
  let c = v * s;
  let x = c * (1 - Math.abs((h / 60) % 2 - 1));
  let m = v - c;
  let r = 0, g = 0, b = 0;

  if (0 <= h && h < 60) {
      r = c;
      g = x;
  } else if (60 <= h && h < 120) {
      r = x;
      g = c;
  } else if (120 <= h && h < 180) {
      g = c;
      b = x;
  } // For this case, we won't go beyond 120 degrees

  // Convert to RGB space
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return { r, g, b };
}

// Function to get color based on input from 0 to 100
export const getColorFromRange  =  (n, opacity = 1) => {  // Add opacity parameter with default value of 1
  if (n < 0 || n > 100) {
      throw new Error("Input must be between 0 and 100.");
  }

  let hue = (120 * n) / 100; // Linear scale of hue from 0 (red) to 120 (green)
  let saturation = 1;        // Full saturation (100%)
  let value = 1;             // Full value (brightness, 100%)

  // Convert the HSV value to RGB
  const { r, g, b } = hsvToRgb(hue, saturation, value);

  // Return the color in RGBA format
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}


export function resizeBase64Image(base64Str, maxWidth, maxHeight) {
  return new Promise((resolve) => {
      const img = new Image();
      img.src = base64Str;
      img.onload = () => {
          const originalWidth = img.width;
          const originalHeight = img.height;

          // Calculate new dimensions while maintaining aspect ratio
          let width = originalWidth;
          let height = originalHeight;
          if (width > maxWidth || height > maxHeight) {
              const aspectRatio = width / height;
              if (width > height) {
                  width = maxWidth;
                  height = maxWidth / aspectRatio;
              } else {
                  height = maxHeight;
                  width = maxHeight * aspectRatio;
              }
          }

          // Draw resized image on a canvas
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          // Convert canvas to base64
          resolve(canvas.toDataURL());
      };
  });
}