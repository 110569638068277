import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Paper, Typography, Box, CircularProgress } from '@mui/material';


export default function CheckboxListSecondary({items, title, load, selector}) {
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const handleSelect = (index) => {
    setSelectedIndex(index);
    selector(index)
  };

  return (
    <Paper elevation={3} sx={{ width: '100%', maxHeight: '50vh', padding: 2, display: 'flex', flexDirection: 'column' }}>
      {/* Title outside of scrollable area */}
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      {/* Scrollable list container */}
      <Box sx={{ flex: 1, overflowY: !load && 'auto'}}>
        {load && <Box sx={{height: '50vh', flex: 1, paddingTop: '40px'}}><CircularProgress  /></Box>}
        {!load && <List sx={{minHeight: '40vh'}}>
          {items.map((value) => {
            const labelId = `title-${value.creation_date}`;
            return (
              <ListItem
                key={value.creation_date}
                disablePadding
                onClick={() => handleSelect(value)}
              >
                <ListItemButton
                  selected={selectedIndex === value}
                  sx={{
                    backgroundColor: selectedIndex === value ? 'primary.main' : 'inherit',
                    color: selectedIndex === value ? 'white' : 'inherit',
                    '&:hover': {
                      backgroundColor: selectedIndex === value ? 'primary.dark' : 'action.hover'
                    }
                  }}
                >
                  <ListItemAvatar>
                    <img
                        src={value.pdfPages[0]}
                        style={{ height: '40px' }}
                    />
                  </ListItemAvatar>
                  <ListItemText id={labelId} primary={window.moment(value.creation_date, "YYYYMMDD").fromNow()} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>}
      </Box>
    </Paper>
  );
}
