export const UserReducer = (state, action) => {
    switch (action.type) {
        case 'populateUser':
            return action.user;
        default:
            return state;
    }
}

export const CurrentAnalyse = (state, action) => {
    switch (action.type) {
        case 'CurrentAnalyse':
            return action.analyse;
        default:
            return state;
    }
}

export const FlowReducer = (state, action) => {
    switch (action.type) {
        case 'populateFlow':
            return action.flow;
        case 'AddStepToFlow':
            return [...state, action.flow_step]
        default:
            return state;
    }
}

export const mainInitialState = {
    api_user: {},
    current_analyse: {},
    flow: [
        {'type': 'Welcome', 'title': 'discu', 'id': 'Welcome'},
        {'type': 'DisplayAnalyseChoice', 'title': 'Choisir un type d\'analyse', 'id': 'choosingType'}
    ]
}

export const mainReducer = (mainInitialState, action) => {
    console.log(action.type, action);
    console.log('state', mainInitialState);
    return {
        api_user: UserReducer(mainInitialState.api_user, action),
        current_analyse: CurrentAnalyse(mainInitialState.current_analyse, action),
        flow: FlowReducer(mainInitialState.flow, action),
    }
}
