import {useState, useEffect} from 'react';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid2'; // Use '@mui/material/Unstable_Grid2' for Grid2
import { useNavigate } from "react-router-dom";
import { getLocalStorageItem, setLocalStorageItem } from "../../../utils/contentData";

function SimulatorPage() {
  const navigate = useNavigate();
  const [user, setUser] = useState(getLocalStorageItem('user'));

  window.addEventListener("storage",(function(e){
      setUser(getLocalStorageItem('user'));
  }).bind(this));

  useEffect(() =>  {
    if (user.svg){
      navigate('/login')
    }
  }, [user])

  return (
    <Grid container spacing={2} sx={{ height: '100vh', padding: 2 }}>
      {/* First Section */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            backgroundColor: 'lightblue',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 1,
          }}
        >
          Section 1
        </Box>
      </Grid>

      {/* Second Section */}
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            backgroundColor: 'lightgreen',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 1,
          }}
        >
          Section 2
        </Box>
      </Grid>
    </Grid>
  );
}

export default SimulatorPage;
