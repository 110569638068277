import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  CircularProgress,
  Alert,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useFetch from "../../../api/api_call";
import { getLocalStorageItem } from "../../../utils/contentData";

export default function FeedbackModal({ open, handleClose }) {
  const [feedbackData, setFeedbackData] = useState({
    subject: "",
    feedback: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { postComment } = useFetch()

  let currentUser = getLocalStorageItem("user");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedbackData({ ...feedbackData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage(""); // Reset error message

  
    try {
      await postComment(feedbackData.feedback)
      setFeedbackData({ subject: "", feedback: "" });
      handleClose();
    } catch (error) {
      setErrorMessage("There was an error submitting your feedback. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h6" component="h2">
          Qu'avez-vous à nous dire ?
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers style={{minWidth: '20vh'}}>
        {currentUser.svg ? (
          <Alert severity="warning" sx={{ mt: 2 }}>
            Vous devez vous connecter pour laisser un commentaire
          </Alert>
        ) : (
          <>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <div>

              <TextField
                fullWidth
                name="feedback"
                multiline={true}
                rows="10"
                placeholder='Description'
                onChange={handleChange}
                value={feedbackData.feedback}
                variant="outlined"
              />
            </div>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          color="secondary"
        >
          Annuler
        </Button>
        {currentUser?.name && (
          <Button
            type="submit"
            color="primary"
            disabled={loading || feedbackData.feedback.length === 0}
            onClick={handleSubmit}
            startIcon={loading && <CircularProgress size={20} />}
          >
            {loading ? " ..." : "Valider"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
