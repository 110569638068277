import {useState} from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import MuiDrawer, { drawerClasses } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MenuContent from './MenuContent';
import CardAlert from './CardAlert';
import OptionsMenu from './OptionsMenu';
import { getLocalStorageItem } from '../../../utils/contentData'
import logo from "../../../assets/logo2.svg";
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer)({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  mt: 10,
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
});

const getUserWidget = (navigate, user) => {

  if (user) {
  return <>
          {user.picture && <Avatar
                sizes="small"
                alt={user.name}
                src={user.picture}
                sx={{ width: 24, height: 24 }}
              />
            }
            {user.svg && <svg width="24" height="24" data-jdenticon-value={user.name}></svg>}
          <Box sx={{ mr: 'auto' }}>
            <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }}>
              {user.name}
            </Typography>
          </Box>
          <OptionsMenu />
        </>
  } else {
    return <Box sx={{ m: 'auto', display: 'flex', width: "100%", justifyContent: "space-around" }}>
      <Button sx={{width: "100%" }}  variant="outlined" endIcon={<LoginRoundedIcon fontSize="small" sx={{ color: '#6C1EFF'}}/> } onClick={() => { navigate('/login')}}>
        Se connecter
      </Button>
    </Box>
  }
}

export default function SideMenu() {
  const navigate = useNavigate();
  const [user, setUser] = useState(getLocalStorageItem('user'));

  window.addEventListener("storage",(function(e){
    setUser(getLocalStorageItem('user'));
 }).bind(this));
  
  return (
    <Drawer
      variant="permanent"
      sx={{
        display: { xs: 'none', md: 'block' },
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: 'background.paper',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          mt: 'calc(var(--template-frame-height, 0px) + 4px)',
          p: 1.5,
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img className="mb-3 app-logo" src={logo} alt="Epimoni logo" width="50" />
          <span style={{ paddingLeft: 10 }}>
            <b style={{ fontSize: 35, fontFamily: "sans-serif" }}>
              EPIMONI
            </b>
          </span>
        </div>
      </Box>
      <Divider />
      <MenuContent />
      <CardAlert />
      <Stack
        direction="row"
        sx={{
          p: 2,
          gap: 1,
          alignItems: 'center',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        {getUserWidget(navigate, user)}
      </Stack>
    </Drawer>
  );
}
