import {useEffect} from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Stack from '@mui/material/Stack';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import { useState } from 'react';
import {setLocalStorageItem, getLocalStorageItem} from '../../../utils/contentData'
import { useNavigate } from 'react-router-dom';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import CompareIcon from '@mui/icons-material/Compare';
import ForumIcon from '@mui/icons-material/Forum';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { toast } from 'react-toastify';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));


// Icon styling with hover effect
const ColorlibStepIconRoot = styled('div')(({ theme, active, focusColor }) => ({
  backgroundColor: active ? focusColor : '#d3d3d3',
  zIndex: 1,
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'background-color 0.3s, color 0.3s, transform 0.2s',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.1)',
    backgroundColor: active ? focusColor : '#b0b0b0', // Darken slightly on hover
  },
}));

function ColorlibStepIcon(props) {
  const { active, icon, completed, focusColor, className } = props;

  const icons = {
    1: <AnalyticsRoundedIcon style={{ color: active ? '#fff' : '#a0a0a0' }} />,
    2: <AssignmentRoundedIcon style={{ color: active ? '#fff' : '#a0a0a0' }} />,
    3: <PeopleRoundedIcon style={{ color: active ? '#fff' : '#a0a0a0' }} />,
    4: <CompareIcon style={{ color: active ? '#fff' : '#a0a0a0' }} />,
    5: <ForumIcon style={{ color: active ? '#fff' : '#a0a0a0' }} />,
  };

  return (
    <ColorlibStepIconRoot className={className} active={active} focusColor={focusColor}  ownerState={{ active }}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

// Steps array with specific colors for each icon
const steps = [
  { label: 'Curriculum Vitae', icon: 1, nav: '/dashboard/cv', color: '#5a9f42' },
  { label: "Offre d'emploi", icon: 2, nav: '/dashboard/offres', color: '#3490db' },
  { label: 'Lettres de motivation', icon: 3, nav: '/dashboard/motivation', optional: true, color: '#db7f34' },
  { label: 'CV ⚔️ Offre', icon: 4, nav: '/dashboard/cv-vs-offre', restricted: true, color: '#db47da' },
  { label: "Simulateur d'entretien", icon: 5, nav: '/dashboard/simulateur', restricted: true, color: '#FFD700' },
];


export default function MainStepper() {
  const navigate = useNavigate();
  const [activeSteps, setActiveSteps] = useState([0,1,2]); // List of active steps

  const [user, setUser] = useState(getLocalStorageItem('user'));

  window.addEventListener("storage",(function(e){
      setUser(getLocalStorageItem('user'));
  }).bind(this));

  useEffect(() =>  {
    if (!user.svg){
      setActiveSteps([0,1,2,3,4])
    }
  }, [user])

  let isUserConnected = !user.svg

  const handleStepClick = (index, step) => {
    if (step.restricted && !isUserConnected) {
      toast.error('Vous devez être connecté pour accéder à cette section.');
    } else {
      navigate(step.nav)
    }
  };

function toNumber(value) {
    return Number(value);
 }

 console.log(activeSteps)

  return (
    <Box sx={{ height: '85vh', width: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h4" gutterBottom>Notre Méthodologie</Typography>
        <Divider sx={{ mb: 2 }} />
      </Box>

      <Box sx={{ paddingX: 2, paddingBottom: 2 }}>
        <Alert severity="info">
          <Typography variant="h6" gutterBottom>Pourquoi est-il important de fournir du contexte ?</Typography>
          <Typography variant="body2">
            Fournir un CV, une offre d'emploi, et éventuellement une lettre de motivation permet d'offrir un contexte riche à l'analyse.
            Cela nous aide à personnaliser les résultats, à identifier précisément les correspondances entre vos compétences et les besoins de l'employeur,
            et à vous donner des recommandations pertinentes pour chaque étape de votre candidature.
          </Typography>
        </Alert>
      </Box>

      <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', paddingX: 2 }}>
        <Box sx={{ width: '100%' }}>
        <Stepper alternativeLabel connector={<ColorlibConnector />} activeStep={Math.max(...activeSteps.map(toNumber))}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel
                  StepIconComponent={(props) => (
                    <ColorlibStepIcon
                      {...props}
                      active={activeSteps.includes(index)}
                      focusColor={step.color}
                      icon={step.icon}
                    />
                  )}
                  onClick={() => handleStepClick(index, step)}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h6" color="textSecondary" align="center" gutterBottom>
                    {step.label}
                  </Typography>
                  {step.optional && (
                    <Chip label="Optionnel" color="default" size="small" sx={{  }} />
                  )}
                  {step.restricted && !isUserConnected && (
                    <Typography variant="caption" color="error">
                      Connectez-vous pour accéder à cette section.
                    </Typography>
                  )}
                  <Box style={{height: '15px'}}></Box>
                  {step.restricted && !isUserConnected && <Button variant="contained" endIcon={<LoginRoundedIcon fontSize="small" sx={{ color: '#6C1EFF'}}/> } onClick={(e) => { e.preventDefault();e.stopPropagation(); navigate('/login')}}>
                    Se connecter
                    </Button>
                  }
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
    </Box>
  );
}
