import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import logo from "../../assets/logo2.svg";

const items = [
  {
    icon: <SettingsSuggestRoundedIcon sx={{ color: '#6C1EFF' }} />,
    title: 'Analyse Personnalisée',
    description:
      'Obtenez une analyse détaillée de votre CV et des offres d’emploi, adaptée à vos compétences.',
  },
  {
    icon: <ConstructionRoundedIcon sx={{ color: '#6C1EFF' }} />,
    title: 'Recommandations Précises',
    description:
      'Profitez de conseils personnalisés pour chaque étape de votre candidature.',
  },
  {
    icon: <ThumbUpAltRoundedIcon sx={{ color: '#6C1EFF' }} />,
    title: 'Simulateur d’Entretien',
    description:
      'Préparez-vous avec des simulations d’entretien interactives et réalistes.',
  },
  {
    icon: <AutoFixHighRoundedIcon sx={{ color: '#6C1EFF' }} />,
    title: 'Outils de Correspondance',
    description:
      'Identifiez les correspondances entre vos compétences et les exigences du poste pour mieux réussir.',
  },
];

export default function Content() {
  return (
    <Stack
      sx={{ flexDirection: 'column', alignSelf: 'center', gap: 5, maxWidth: 550 }}
    >
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <div style={{ padding: 10, paddingTop: 13 }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img className="mb-3 app-logo" src={logo} alt="Epimoni logo" width="50" />
            <span style={{ paddingLeft: 10 }}>
              <b style={{ fontSize: 50, fontFamily: "sans-serif" }}>
                EPIMONI
              </b>
            </span>
          </div>
        </div>
      </Box>
      {items.map((item, index) => (
        <Stack key={index} direction="row" sx={{ gap: 2 }} style={{ fontFamily: "sans-serif" }}>
          {item.icon}
          <div>
            <Typography gutterBottom sx={{ fontWeight: 'medium', fontSize: 'larger' }}>
              {item.title}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: 'medium' }}>
              {item.description}
            </Typography>
          </div>
        </Stack>
      ))}
    </Stack>
  );
}
