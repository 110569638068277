import React, { useState, useContext, useEffect } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import ForumIcon from '@mui/icons-material/Forum';
import FeedbackModal from '../components/FeedbackModal'
import CompareIcon from '@mui/icons-material/Compare';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { useNavigate } from "react-router-dom";
import {  useLocation } from 'react-router-dom';
import { Divider } from "@mui/material";
import useFetch from "../../../api/api_call";

const get_style = ({selected, item}) => {
  if (selected) {
    return {'color': item.color}
  }
  return {}
}


const mainListItems = [
  { text: 'Accueil', icon: (props) => <HomeRoundedIcon style={get_style(props)}/>, link: "/dashboard", color: '' },
  { text: 'Curriculum Vitae', icon: (props) => <AnalyticsRoundedIcon style={get_style(props)}/>, link: "/dashboard/cv", color: '#43a329' },
  { text: 'Offres', icon: (props) => <AssignmentRoundedIcon style={get_style(props)}/>, link: "/dashboard/offres", color: '#3490db' },
  { text: 'Lettres de motivation', icon: (props) => <PeopleRoundedIcon style={get_style(props)}/>, link: "/dashboard/motivation", color: '#db7f34' },
];

const ComplexProductListItems = [
  { text: 'CV ⚔️ OFFRE', icon: (props) => <CompareIcon style={get_style(props)}/>, link: "/dashboard/cv-vs-offre", color: '#db47da'},
  { text: "Simulateur d'entretien", icon: (props) => <ForumIcon style={get_style(props)}/>, link: "/dashboard/simulateur", color: '#FFD700'},
];

const secondaryListItems = [
  { text: 'Vos retours !', icon: (props) => <HelpRoundedIcon style={get_style(props)}/>, link: "action:feedback"},
];

export default function MenuContent() {

  const navigate = useNavigate();
  const location = useLocation();

  const [showModal, setShowModal] =  useState(false)
  const [user, setUser] =  useState(null)
  const [selectedIndex, setSelectedIndex] =  useState('')

  const { getCurrentUser } = useFetch()

  const TYPE_BASE_PRODUCT = 'base'
  const TYPE_COMPLEX_PRODUCT = 'complex'

  const handleClick = (type, action, index) => {
    if (action === 'action:feedback') {
      setShowModal(true)
    } else {
      setSelectedIndex(type +'_'+ index)
      navigate(action)
    }
  }

  useEffect(()  => {
    getCurrentUser().then((res) => setUser(res))
  }, [])

  useEffect(()  => {
    if (location.pathname === '/dashboard') {
      setSelectedIndex(TYPE_BASE_PRODUCT +'_'+ 0)
    }
    if (location.pathname === '/dashboard/cv') {
      setSelectedIndex(TYPE_BASE_PRODUCT +'_'+ 1)
    }
    if (location.pathname === '/dashboard/offres') {
      setSelectedIndex(TYPE_BASE_PRODUCT +'_'+ 2)
    }
    if (location.pathname === '/dashboard/motivation') {
      setSelectedIndex(TYPE_BASE_PRODUCT +'_'+ 3)
    }


    if (location.pathname === '/dashboard/cv-vs-offre') {
      setSelectedIndex(TYPE_COMPLEX_PRODUCT +'_'+ 0)
    }
    if (location.pathname === 'dashboard/simulateur') {
      setSelectedIndex(TYPE_COMPLEX_PRODUCT +'_'+ 1)
    }
  }, [location])


  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: 'space-between' }}>
      <List dense>
        {mainListItems.map((item, index) => (
          <ListItem key={item.link} disablePadding sx={{ display: 'block' }} onClick={() => handleClick(TYPE_BASE_PRODUCT, item.link, index)}>
            <ListItemButton selected={selectedIndex.startsWith(TYPE_BASE_PRODUCT) && index == selectedIndex.slice(-1)}>
              <ListItemIcon>{item.icon({'selected': selectedIndex.startsWith(TYPE_BASE_PRODUCT) && index == selectedIndex.slice(-1), 'item': item})}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
        <span style={{height: 10}}/>
        <Divider  />
        <span style={{height: 10}}/>
        {ComplexProductListItems.map((item, index) => (
          <ListItem key={item.link} disablePadding sx={{ display: 'block' }} onClick={() => handleClick(TYPE_COMPLEX_PRODUCT, item.link, index)}>
            <ListItemButton selected={selectedIndex.startsWith(TYPE_COMPLEX_PRODUCT) && index == selectedIndex.slice(-1)}>
            <ListItemIcon>{item.icon({'selected': selectedIndex.startsWith(TYPE_COMPLEX_PRODUCT) && index == selectedIndex.slice(-1), 'item': item})}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>


      <List dense>
        {secondaryListItems.map((item, index) => (
          <ListItem key={item.link} disablePadding sx={{ display: 'block' }} onClick={() => handleClick(TYPE_BASE_PRODUCT, item.link, index)}>
            <ListItemButton>
            <ListItemIcon>{item.icon({'selected': selectedIndex.startsWith(TYPE_BASE_PRODUCT) && index == selectedIndex.slice(-1), 'item': item})}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <FeedbackModal open={showModal} handleClose={() => setShowModal(false)} user={user}/>
    </Stack>
  );
}
