import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Divider, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid2';
import useFetch from "../../../api/api_call";
import RocketIcon from '@mui/icons-material/Rocket';
import CheckboxListSecondary from '../components/CheckboxListSecondary'
import VersusPageDetail from '../components/VersusPageDetail'
import { useNavigate } from "react-router-dom";
import { getLocalStorageItem, setLocalStorageItem } from "../../../utils/contentData";


const VersusPage = () => {
  const navigate = useNavigate();


  const [cvs, setCvs] = useState([]);
  const [offers, setOffers] = useState([]);

  const [selectedCv, setSelectedCV] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);

  const [loadCv, setLoadCv] = useState(true);
  const [loadOffer, setLoadOffer] = useState(true);

  const [loadAnalyse, setLoadAnalyse] = useState(false);

  const { getListOfUserOffers, getListOfUserCv, startDeepCvVsOfferAnalyse } = useFetch()

  const [user, setUser] = useState(getLocalStorageItem('user'));

  window.addEventListener("storage",(function(e){
      setUser(getLocalStorageItem('user'));
  }).bind(this));

  useEffect(() =>  {
    if (user.svg){
      navigate('/login')
    }
  }, [user])

  useEffect(() => {
    let ignore = false
    const fetchData = async () => {
      setLoadCv(true)
      setLoadOffer(true)
      let valueCV = await getListOfUserCv()
      let valueOffer = await getListOfUserOffers()
      if(!ignore){
        setCvs(valueCV.json.cvs);
        setOffers(valueOffer.json.offers)
        setLoadCv(false)
        setLoadOffer(false)
      }
    }
    fetchData()
    return () => {ignore=true}
  }, [])

  const handleApiCall = async () => {
    setLoadAnalyse(true);
    let ok =  await startDeepCvVsOfferAnalyse(selectedCv.cv_id, selectedOffer.offer_id)
    console.log('ANALYSE RETURN', ok)
    setLoadAnalyse(false);
    let valueCV = await getListOfUserCv()
    let valueOffer = await getListOfUserOffers()
    setCvs(valueCV.json.cvs);
    setOffers(valueOffer.json.offers)
  }

  console.log(selectedCv)
  console.log(selectedOffer)

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' }, mx: 'auto', mt: 4}}>
      <Typography component="h2" variant="h6" sx={{ mb: 4 }}>
        Comparez votre CV et une offre d'emploi
      </Typography>

      <Divider />

      <Grid container justifyContent="center" spacing={2} style={{paddingTop: 100, justifyContent: 'space-evenly'}}>
        {/* Section gauche : Upload de fichier */}
        <Grid sx={{width: '30%', height: '70%'}} display="flex"  textAlign='center' justifyContent="center">
          <CheckboxListSecondary title={"Selectionner votre CV"} items={cvs} load={loadCv} selector={setSelectedCV}/>
        </Grid>

        {/* Icône de séparation ⚔️ */}
        <Grid  display="flex" flexDirection="column" justifyContent="center" alignItems="center" fontSize={'3rem'}>
          <Typography variant="h2">⚔️</Typography>
          
          <Box display="flex" justifyContent="center" mt={4}>
            <Button
              variant={((!selectedCv || !selectedOffer) || loadAnalyse) ? "outlined" : "contained"}
              onClick={() => handleApiCall()}
              disabled={(!selectedCv || !selectedOffer) || loadAnalyse}
              startIcon={loadAnalyse ? <CircularProgress size={24} /> : <RocketIcon sx={{ fontSize: 40 }} />}
            >
              Comparer
            </Button>
          </Box>
        </Grid>

        <Grid sx={{width: '30%', height: '70%'}} display="flex"  textAlign='center' justifyContent="center">
          <CheckboxListSecondary title={"Selectionner une offre"} items={offers} load={loadOffer} selector={setSelectedOffer}/>
        </Grid>
      </Grid>
      <Box>
        <VersusPageDetail cvs={cvs} offers={offers}/>
      </Box>
    </Box>
  );
};

export default VersusPage;
