import * as React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { getLocalStorageItem } from '../../../utils/contentData'
import MenuButton from './MenuButton';
import MenuContent from './MenuContent';
import CardAlert from './CardAlert';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import { useNavigate } from "react-router-dom";


const getMobileUserWidget = (navigate) => {
  let user = getLocalStorageItem('user')
  if (user) {
    return <>
            {user.picture && <Avatar
                sizes="small"
                alt={user.name}
                src={user.picture}
                sx={{ width: 24, height: 24 }}
              />
            }
            {user.svg && <svg width="24" height="24" data-jdenticon-value={user.name}></svg>}
            <Typography component="p" variant="h6">
              {user.name}
            </Typography>
          </>
  } else {
    return <Button sx={{width: "100%" }}  variant="outlined" endIcon={<LoginRoundedIcon fontSize="small" sx={{ color: '#6C1EFF'}}/>} onClick={() => navigate('/login')}>
            Se connecter
          </Button>
  }
}

function SideMenuMobile({ open, toggleDrawer }) {
  const navigate = useNavigate();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          backgroundImage: 'none',
          backgroundColor: 'background.paper',
        },
      }}
    >
      <Stack
        sx={{
          maxWidth: '70dvw',
          height: '100%',
        }}
      >
        <Stack direction="row" sx={{ p: 2, pb: 0, gap: 1 }}>
          <Stack
            direction="row"
            sx={{ gap: 1, alignItems: 'center', flexGrow: 1, p: 1 }}
          >
            {getMobileUserWidget(navigate)}
          </Stack>
          <MenuButton showBadge>
            <NotificationsRoundedIcon />
          </MenuButton>
        </Stack>
        <Divider />
        <Stack sx={{ flexGrow: 1 }}>
          <MenuContent />
          <Divider />
        </Stack>
        <Stack>
          <Button variant="outlined" fullWidth startIcon={<LogoutRoundedIcon />} onClick={() => navigate('/login')} >
              Logout
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
}

SideMenuMobile.propTypes = {
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
};

export default SideMenuMobile;
